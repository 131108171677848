<template>
  <v-snackbar
    v-model="snackbar"
    absolute
    :elevation="0"
    right
    top
    :color="this.$store.getters['snackbarStore/getSnackbar'].type"
    :timeout="3000"
  >
    {{ text }}
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {
      snackbar: false,
      text: '',
    };
  },
  computed: {
    computedMessage() {
      return this.$store.getters['snackbarStore/getSnackbar'].snackbarMessage;
    },
    computedSnackbar() {
      return this.$store.getters['snackbarStore/getSnackbar'].snackbar;
    },
  },
  watch: {
    computedSnackbar(newVal) {
      this.snackbar = newVal;
    },
    computedMessage(newVal) {
      this.text = newVal;
    },
  },
};
</script>
