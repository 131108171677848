var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialog.value),callback:function ($$v) {_vm.$set(_vm.dialog, "value", $$v)},expression:"dialog.value"}},[_c('v-card',{attrs:{"elevation":0,"rounded":"xl"}},[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v(" Confirm Deletion ")]),_c('v-spacer'),_c('Button',{attrs:{"btn":{
          type: 'button',
          customClass: '',
          block: false,
          icon: 'mdi-close',
        }},on:{"click":function($event){_vm.dialog.value = false}}})],1),_c('v-card-subtitle',{staticClass:"mt-3"},[_vm._v(" Are you sure you want to delete "+_vm._s(_vm.deleteType)+" ")]),_c('v-card-actions',{staticClass:"pb-5"},[_c('v-spacer'),_c('Button',{attrs:{"loading":_vm.loading,"btn":{
          type: 'submit',
          color: 'primary',
          customClass: 'mt-4',
          label: 'Delete',
        }},on:{"click":function($event){return _vm.$emit('confirmDelete')}}}),_c('Button',{attrs:{"btn":{
          type: 'submit',
          customClass: 'mt-4',
          label: 'Cancel',
        }},on:{"click":function($event){_vm.dialog.value = false}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }