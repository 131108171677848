const emailRule = [
  (v) => !!v || 'Email is required',
  (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
  (v) => !/\s/.test(v) || 'Email should not contain spaces',
];

const nameRule = [
  (v) => !!v.trim() || 'Name is required',
  (v) =>
    /^[A-Za-z\s]+$/.test(v) ||
    'Name must contain only alphabetic characters or spaces',
  (v) => (v && v.length >= 2) || 'Name must be at least 2 characters',
  (v) => (v && v.length <= 20) || 'Name must be at most 20 characters',
];

const passwordRule = [
  (v) => !!v || 'Password is required',
  (v) => (v && v.length >= 8) || 'Password must be at least 8 characters',
  (v) =>
    /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
  (v) => /[0-9]/.test(v) || 'Password must contain at least one number',
  (v) =>
    /[!@#$%^&*(),.?":{}|<>]/.test(v) ||
    'Password must contain at least one special character',
  (v) => !/\s/.test(v) || 'Password should not contain spaces', // Space check
];
export default {
  emailRule,
  nameRule,
  passwordRule,
};
