export default {
  namespaced: true,
  state: () => ({
    snackbar: false,
    snackbarMessage: '',
    type: 'success',
  }),
  mutations: {
    showSnackbar(state, payload) {
      state.snackbar = true;
      state.snackbarMessage = payload.message;
      state.type = payload.type;
      setTimeout(() => {
        state.snackbar = false;
        state.snackbarMessage = '';
      }, 3100);
    },
  },
  actions: {
    showSnackbar({ commit, state }, payload) {
      commit('showSnackbar', (state, payload));
    },
  },
  getters: {
    getSnackbar(state, getters) {
      return state;
    },
  },
};
