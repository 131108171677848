import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    folders: {},
  },
  mutations: {
    setFolderName(state, payload) {
      state.folders[payload.id] = { name: payload.name };
    },
  },
  getters: {
    getFolderName: (state) => (id) => {
      return state.folders[id] && state.folders[id].name;
    },
  },
});
