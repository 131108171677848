<template>
  <v-app>
    <Sidebar
      :drawer="drawer"
      v-if="isAuth && !isVideoRoute && !checkPageNotFound"
    />
    <Snackbar />
    <Navbar
      v-if="isAuth && !isVideoRoute && !checkPageNotFound"
      @showSidebar="handleSidebar"
    />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from './components/common/Snackbar.vue';
import Sidebar from './layout/Sidebar.vue';
import { mapGetters } from 'vuex';
import Navbar from './layout/Navbar.vue';

export default {
  components: { Snackbar, Sidebar, Navbar },
  computed: {
    ...mapGetters('userStore', ['isAuth']),
    isVideoRoute() {
      return this.$route.name === 'video';
    },
    checkPageNotFound() {
      return this.$route.name === 'PageNotFound';
    },
  },
  data() {
    return {
      drawer: {
        value: true,
      },
    };
  },

  methods: {
    handleSidebar() {
      this.drawer.value = !this.drawer.value;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  font-family: 'Poppins', sans-serif;
}

.homescreen-text {
  font-size: 20px;
  font-weight: 400;
  color: black;
}
.v-overlay--active {
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.043);
}
.v-snack--absolute {
  z-index: 99999 !important;
}
.v-snack--absolute {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
}
</style>
<style lang="scss" scoped></style>
