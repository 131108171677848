import urlServices from '@/helpers/urlServices';
import { apiRequest } from '@/services/apiRequest';
import { getAvatarColor } from '@/helpers/avatarColorUtil';

const state = {
  workspaces: [],
  currentWorkspace: null,
};

const mutations = {
  ADD_WORKSPACE(state, workspace) {
    if (workspace && workspace.id && workspace.name) {
      const exists = state.workspaces.some((ws) => ws.id === workspace.id);

      if (!exists) {
        // Assign a color to the workspace based on its name
        workspace.color = getAvatarColor(workspace.name);
        state.workspaces.push(workspace);
      }
    } else {
      console.error('Invalid workspace data:', workspace);
    }
  },
  UPDATE_WORKSPACE_NAME(state, { oldName, newName }) {
    const workspace = state.workspaces.find((ws) => ws.name === oldName);
    if (workspace) {
      workspace.name = newName;

      // Update the color based on the new name
      workspace.color = getAvatarColor(newName);

      if (state.currentWorkspace.name === oldName) {
        state.currentWorkspace.name = newName;
        state.currentWorkspace.color = getAvatarColor(newName);
      }
    }
  },
  REMOVE_WORKSPACE(state, workspaceId) {
    const index = state.workspaces.findIndex((ws) => ws._id === workspaceId);
    if (index !== -1) {
      state.workspaces.splice(index, 1);
    }
  },
  SET_WORKSPACES(state, workspaces) {
    // Assign colors to each workspace based on their name
    state.workspaces = workspaces.map((workspace) => ({
      ...workspace,
      color: getAvatarColor(workspace.name),
    }));
  },
  setWorkspaceData(state, workspace) {
    // Assign color to current workspace
    workspace.color = getAvatarColor(workspace.name);
    state.currentWorkspace = workspace;
  },
};

const actions = {
  async addWorkspace({ commit, state }, workspace) {
    if (workspace && workspace.id) {
      const exists = state.workspaces.some((ws) => ws.id === workspace.id);

      if (!exists) {
        commit('ADD_WORKSPACE', workspace);
      }
    } else {
      this.error(error);
    }
  },
  updateWorkspaceName({ commit }, payload) {
    commit('UPDATE_WORKSPACE_NAME', payload);
  },
  removeWorkspace({ commit }, workspaceId) {
    commit('REMOVE_WORKSPACE', workspaceId);
  },
  currentWorkspace({ commit }, workspace) {
    commit('setWorkspaceData', workspace);
  },
  async fetchWorkspaces({ commit }) {
    try {
      await apiRequest(urlServices.getAllWorkSpaces, 'GET')
        .then((response) => {
          if (response.data && response.data?.data?.workspaces)
            commit('SET_WORKSPACES', response.data?.data?.workspaces);
        })
        .catch((Error) => {
          console.log('ERROR in fetch Workspaces', Error);
        });
    } catch (error) {
      console.error('Error fetching workspaces:', error);
    }
  },
};

const getters = {
  allWorkspaces: (state) => state.workspaces,
  getWorkspace: (state) => state.currentWorkspace,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
