<template>
  <v-list class="pa-0 overflow-y-auto" max-height="535" rounded dense>
    <v-list-item
      link
      v-for="workspace in workspaces"
      :key="workspace._id"
      active-class="list-active"
      @click="workspaceClicked(workspace)"
      :class="{
        'workspace-item': true,
        'active-workspace': $route.params.workspaceId === workspace.id,
      }"
    >
      <v-list-item-icon class="pb-3 mr-2">
        <v-avatar
          :style="{ backgroundColor: workspace.color, color: 'black' }"
          size="28px"
        >
          {{ workspace && workspace.name.charAt(0).toUpperCase() }}
        </v-avatar>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title
          :class="{
            'workspace-title': true,
            'active-title': selectedWorkspace === workspace.name,
          }"
        >
          <TruncateText :text="workspace.name" :max-length="15"></TruncateText>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action v-if="workspace.status === 'private'">
        <v-tooltip content-class="tooltip-button" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="lock-icon" v-bind="attrs" v-on="on">
              mdi-lock
            </v-icon>
          </template>
          <span>Access by invitation only</span>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>

    <DeleteConfirmationModal
      :dialog="isDeleteDialogVisible"
      @confirmDelete="deleteWorkspace"
      deleteType="this Workspace?"
      :loading="deleteWorkspaceLoading"
    />
  </v-list>
</template>

<script>
import urlServices from '@/helpers/urlServices';
import { mapActions, mapGetters } from 'vuex';
import DeleteConfirmationModal from '../../components/common/modals/DeleteConfirmationModal.vue';
import TruncateText from '@/components/common/TruncateText.vue';

export default {
  components: { DeleteConfirmationModal, TruncateText },
  data() {
    return {
      showAll: false,
      selectedWorkspace: null,
      isDeleteDialogVisible: { value: false },
      workspaceToDelete: null,
      deleteWorkspaceLoading: false,
    };
  },
  methods: {
    ...mapActions('workspaces', ['currentWorkspace']),

    getRandomLightColor() {
      const red = Math.floor(Math.random() * 111) + 70; // Range: 70 - 180
      const green = Math.floor(Math.random() * 111) + 70; // Range: 70 - 180
      const blue = Math.floor(Math.random() * 111) + 70; // Range: 70 - 180

      // Convert RGB to hex format
      const toHex = (c) => c.toString(16).padStart(2, '0');
      return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
    },

    /**
     * Determines the avatar color based on the given name with darker shades.
     *
     * @param {string} name - The name used to compute the avatar color.
     * @returns {string} - The hexadecimal color code for the avatar.
     */
    getAvatarColor(name) {
      if (!name) {
        return 'hsl(0, 0%, 50%)'; // Default dark grey for invalid input
      }

      const hash = this.getUniqueHash(name);

      // Adjust the range for darker shades
      const hue = hash % 360;
      const saturation = 50 + (hash % 20); // Range: 50-69% (lowered for darker shades)
      const lightness = 40 + (hash % 10); // Range: 40-49% (darker lightness)

      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },

    /**
     * Generates a unique hash for a given string.
     *
     * @param {string} name - The string to hash.
     * @returns {number} - A positive integer hash value.
     */
    getUniqueHash(name) {
      let hash = 0;

      // Simple hash function
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }

      return Math.abs(hash); // Ensure the hash is non-negative
    },

    /**
     * Handles the click event for a workspace.
     *
     * @param {string} name - The name of the clicked workspace.
     */
    workspaceClicked(workspace) {
      this.selectedWorkspace = workspace.name;
      if (this.$route.path !== `/workspace/${workspace.id}`) {
        this.currentWorkspace(workspace);
        this.$router.replace(`/workspace/${workspace.id}`);
      }
    },

    /**
     * Shows a confirmation dialog for deleting a workspace.
     *
     * @param {Object} workspace - The workspace object to be deleted.
     */
    confirmDeleteWorkspace(workspace) {
      this.workspaceToDelete = workspace;
      this.isDeleteDialogVisible.value = true;
    },
    /**
     * Deletes the selected workspace after confirmation.
     *
     * @returns {Promise<void>} - A promise that resolves when the deletion is complete.
     * @throws {Error} - Throws an error if there is a problem with the deletion process.
     */
    async deleteWorkspace() {
      if (!this.isDeleting) {
        this.isDeleting = true;
        try {
          this.deleteWorkspaceLoading = true;
          const workspace_id = this.workspaceToDelete.id;

          await this.apiRequest(urlServices.deleteWorkSpace, 'DELETE', {
            workspace_id,
          })
            .then((response) => {
              this.$store.dispatch('workspaces/fetchWorkspaces');
              this.isDeleteDialogVisible.value = false;
              this.workspaceToDelete = null;
              this.success(response.data.message);
            })
            .catch((Error) => {
              this.error(Error);
            })
            .finally(() => {
              this.deleteWorkspaceLoading = false;
            });
        } catch (error) {
          this.error(error);
        } finally {
          this.isDeleting = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters('workspaces', ['allWorkspaces']),

    workspaces() {
      return this.allWorkspaces;
    },
  },
};
</script>

<style lang="scss" scoped>
.active-workspace {
  background-color: #e4e4e4;
}
</style>
