// store/modules/videoStore.js
const state = {
  videoDetails: {
    duration: '',
    filename: '',
    displayName: '',
  },
};

const mutations = {
  SET_VIDEO_DETAILS(state, { duration, filename, displayName }) {
    state.videoDetails.duration = duration;
    state.videoDetails.filename = filename;
    state.videoDetails.displayName = displayName;
  },
  CLEAR_VIDEO_DETAILS(state) {
    state.videoDetails = {
      duration: '',
      filename: '',
      displayName: '',
    };
  },
};

const actions = {
  updateVideoDetails({ commit }, video) {
    commit('SET_VIDEO_DETAILS', {
      duration: video.duration,
      filename: video.filename,
      displayName: video.display_name,
    });
  },
  clearVideoDetails({ commit }) {
    commit('CLEAR_VIDEO_DETAILS');
  },
};

const getters = {
  videoDetails: (state) => state.videoDetails,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
