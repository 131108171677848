/**
 * Description: This file consists of axios request plugin that can be used to make requests and errors can be handled at once rather than repeating code
 * @param {String} url
 * @param {String} methods
 * @param {Object} data object containing data to be sent with post
 * !Note: Please send empty object {} in case of GET request as parameter
 */
import router from '@/router';
import store from '@/store';
import axios from 'axios';

const baseUrls = process.env.VUE_APP_BASE_URL;

export default {
  install(Vue) {
    Vue.prototype.apiRequest = apiRequest;
  },
};
export const apiRequest = (url, method, data) => {
  let options = {};

  // convert object to formData
  var form_data = new FormData();
  for (const key in data) {
    if (Array.isArray(data[key])) {
      for (const country of data[key]) {
        form_data.append(`${key}[]`, country);
      }
    } else {
      form_data.append(key, data[key]);
    }
  }
  const getToken = localStorage.getItem('token');
  options = {
    url: `${baseUrls}/${url}`,
    method: method,
    data: form_data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: `${localStorage.getItem('token')}`,
    },
  };
  if (url && url.startsWith('api/recordings/stream-file/')) {
    options['headers']['Accept'] = 'video/mp4;charset=UTF-8';
    options.responseType = 'blob';
  }
  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      localStorage.clear();
      if (error.config.url !== baseUrls + '/api/auth/login') {
        store.dispatch('userStore/removeToken');
        router.push('/login');
        localStorage.clear();
        return Promise.reject({
          response: { data: { message: 'Session Expired, Login Again' } },
        });
      }
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
