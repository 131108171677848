<template>
  <v-app-bar dense app :elevation="0">
    <v-app-bar-nav-icon
      @click="$emit('showSidebar')"
      v-if="this.$vuetify.breakpoint.width < 1264"
    ></v-app-bar-nav-icon>
    <v-toolbar-title>{{ currentTab }}</v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('userStore', ['currentTab']),
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-toolbar__content .v-toolbar__title) {
  font-size: 16px;
  font-weight: 600;
  color: #6c6685;
}
</style>
