import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import snackbarStore from './modules/snackbar';
import userStore from '@/store/modules/users/user';
import workspaces from '@/store/modules/workspace/workspaces';
import savedRecordings from './modules/savedRecordings';
import folderName from './modules/folder/folder-name';
import videoStore from '@/store/modules/video-store';
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    userStore: state.userStore,
    workspaces: state.workspaces,
  }),
});

const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    snackbarStore,
    userStore,
    workspaces,
    savedRecordings,
    folderName,
    videoStore,
  },
  plugins: [vuexLocal.plugin],
});
Vue.prototype.$store = store;
export default store;
