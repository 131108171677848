import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: (to, next) => {
      if (localStorage.getItem('token')) {
        return { name: 'saved' };
      }
      return { name: 'login' };
    },
  },

  {
    path: '/user-settings',
    name: 'user-settings',
    component: () => import('@/views/user-settings/UserSettingsView.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next({ path: '/login' });
      } else {
        next();
      }
    },
    children: [
      {
        path: '',
        name: 'my-account',
        component: () =>
          import('@/components/user-profile/user-settings/MyAccount.vue'),
      },
    ],
  },
  {
    path: '/browse-workspace',
    name: 'browse-workspace',
    component: () => import('@/views/work-spaces/BrowseWorkspaceView.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next({ path: '/login' });
      } else {
        next();
      }
    },
  },
  {
    path: '/workspace/:workspaceId',
    name: 'work-space',
    component: () => import('@/views/work-spaces/WorkSpacesView.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next({ path: '/login' });
      } else {
        next();
      }
    },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/components/authentication/SignUp.vue'),
    beforeEnter: (to, from, next) => {
      return localStorage.getItem('token') ? next({ path: '/' }) : next(true);
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/authentication/Login.vue'),
    beforeEnter: (to, from, next) => {
      return localStorage.getItem('token') ? next({ path: '/' }) : next(true);
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/components/authentication/ForgotPassword.vue'),
    beforeEnter: (to, from, next) => {
      return localStorage.getItem('token') ? next({ path: '/' }) : next(true);
    },
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('@/components/authentication/ResetPassword.vue'),
    beforeEnter: (to, from, next) => {
      return localStorage.getItem('token') ? next({ path: '/' }) : next(true);
    },
  },
  {
    path: '/page-not-found',
    name: 'PageNotFound',
    component: () => import('@/components/authentication/PageNotFound.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/saved',
    name: 'saved',
    component: () => import('@/views/saved-recordings/SavedRecordingView.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next({ path: '/login' });
      } else {
        next();
      }
    },
  },
  {
    path: '/video-player/:file_name',
    name: 'video-player',
    component: () =>
      import('@/components/customize-video-editor/VideoPlayer.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next({ path: '/login' });
      } else {
        next();
      }
    },
  },
  {
    path: '/video/:access_type/:file_name/:token?',
    name: 'video',
    component: () => import('@/components/saved-recordings/video.vue'),
    props: true,
  },
  {
    path: '/audio/:access_type/:file_name/:token?',
    name: 'audio',
    component: () => import('@/components/saved-recordings/AudioPlayer.vue'),
    props: true,
  },
  {
    path: '/archive',
    name: 'archive',
    component: () => import('@/components/saved-recordings/Archive.vue'),
  },
  {
    path: '/folder/:id',
    name: 'FolderDetails',
    component: () => import('@/components/saved-recordings/FolderDetails.vue'),
    props: true,
  },
  {
    path: '*',
    redirect: { name: 'PageNotFound' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
