/**
 * The initial state of the Vuex store or component's data properties.
 *
 * @typedef {Object} State
 * @property {Object} user - The current user's information.
 * @property {string} user.id - The unique identifier of the user.
 * @property {string} user.firstName - The first name of the user.
 * @property {string} user.lastName - The last name of the user.
 * @property {string} user.email - The email address of the user.
 * @property {string|null} user.profileImage - The URL of the user's profile image, if available.
 * @property {string} user.maskedPassword - The masked password of the user.
 * @property {string|null} token - The authentication token retrieved from local storage, or `null` if not available.
 */
const state = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    profileImage: '',
    maskedPassword: '******',
  },
  token: localStorage.getItem('token') || null,
  currentTab: '',
};

const mutations = {
  setUserData(state, userData) {
    state.user.firstName = userData.first_name || state.user.firstName;
    state.user.lastName = userData.last_name || state.user.lastName;
    state.user.profileImage = userData.profile_pic || state.user.profileImage;
    state.user.email = userData.email || state.user.email;
    state.user.id = userData._id || state.user.id;
    if (userData && userData?.isRemove) state.user.profileImage = '';
  },
  setToken(state, token) {
    state.token = token;
    localStorage.setItem('token', token);
  },

  removeToken(state) {
    state.token = null;
    localStorage.removeItem('token');
  },

  setCurrentTab(state, payload) {
    state.currentTab = payload;
  },
};

const actions = {
  async updateUserProfile({ commit }, payload) {
    commit('setUserData', {
      first_name: payload.first_name,
      last_name: payload.last_name,
      profile_pic: payload.profile_pic,
    });
  },

  deleteProfilePicture({ commit }) {
    commit('setUserData', { profile_pic: '', isRemove: true });
  },
  setToken({ commit }, token) {
    commit('setToken', token);
  },
  setUserData({ commit }, payload) {
    commit('setUserData', payload);
  },

  removeToken({ commit }) {
    commit('removeToken');
  },

  setCurrentTab({ commit }, payload) {
    commit('setCurrentTab', payload);
  },
};

const getters = {
  user: (state) => state.user,
  isAuth: (state) => !!state.token,
  currentTab: (state) => state.currentTab,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
