<template>
  <v-dialog v-model="dialog.value" max-width="400px">
    <v-card :elevation="0" rounded="xl">
      <v-card-title>
        <span class="text-h6"> Confirm Deletion </span>
        <v-spacer></v-spacer>

        <Button
          @click="dialog.value = false"
          :btn="{
            type: 'button',
            customClass: '',
            block: false,
            icon: 'mdi-close',
          }"
        />
      </v-card-title>
      <v-card-subtitle class="mt-3">
        Are you sure you want to delete {{ deleteType }}
      </v-card-subtitle>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <Button
          @click="$emit('confirmDelete')"
          :loading="loading"
          :btn="{
            type: 'submit',
            color: 'primary',
            customClass: 'mt-4',
            label: 'Delete',
          }"
        />
        <Button
          @click="dialog.value = false"
          :btn="{
            type: 'submit',
            customClass: 'mt-4',
            label: 'Cancel',
          }"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: {},
    },
    deleteType: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
