var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"sidebar-component",attrs:{"permanent":_vm.$vuetify.breakpoint.lgAndUp,"app":"","width":"230px"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-divider',{staticClass:"mx-5"}),_c('v-list',{attrs:{"rounded":"","dense":""}},[_c('v-list-item',{staticClass:"select-list mt-1",attrs:{"link":""},on:{"click":_vm.navigateToBrowseWorkspace}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"20","color":"black"}},[_vm._v("mdi-magnify")])],1),_c('v-list-item-content',[_c('v-list-item-content',[_c('span',[_vm._v("Browse Workspaces")])])],1)],1)],1),_c('v-menu',{attrs:{"rounded":"xl","transition":"slide-x-transition","max-width":"150","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list',{staticClass:"mb-2",attrs:{"dense":"","rounded":""}},[_c('v-list-item',_vm._g(_vm._b({staticClass:"select-list",attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"20","color":"black"}},[_vm._v(" mdi-account-outline ")])],1),_c('v-list-item-content',[_c('span',[_c('TruncateText',{attrs:{"text":_vm.user.firstName + ' ' + _vm.user.lastName,"maxLength":15}})],1)])],1)],1)]}}])},[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":12,"max-width":"150"}},[_c('v-list',{staticClass:"py-0",attrs:{"density":"compact"}},[_c('ListItem',{attrs:{"item":{
              title: 'User Settings',
              icon: 'mdi-cog-outline',
              route: '/user-settings',
            }}}),_c('ListItem',{attrs:{"item":{
              title: 'Sign Out',
              icon: 'mdi-logout',
            }},on:{"onClick":_vm.handleLogout}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.drawer.value),callback:function ($$v) {_vm.$set(_vm.drawer, "value", $$v)},expression:"drawer.value"}},[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-title',{staticClass:"d-flex justify-center"},[_c('div',{staticStyle:{"height":"77px"}},[_c('router-link',{attrs:{"to":"/saved"}},[_c('v-img',{staticClass:"py-2",attrs:{"src":require("@/assets/app-logo.84f1d7b6.png"),"max-width":"150px","contain":""}})],1)],1)])],1),_c('v-divider',{staticClass:"mx-5"}),_c('v-list',{staticClass:"list-item-router mb-0 pb-0 mt-1",attrs:{"rounded":"","dense":""}},_vm._l((_vm.items),function(item){return _c('ListItem',{key:item.title,attrs:{"item":item}})}),1),_c('v-list',{attrs:{"rounded":"","dense":""}},[_c('v-list-item',{staticClass:"mb-0"},[_c('v-list-item-title',{staticClass:"text--secondary d-flex align-center justify-space-between"},[_vm._v("Spaces "),_c('v-tooltip',{attrs:{"content-class":"tooltip-button","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.addWorkspaceValue.value = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Create a Workspace")])])],1)],1),_c('CreateWorkspaceModal',{attrs:{"inputValues":_vm.newWorkspaceModelValue,"dialog":_vm.addWorkspaceValue},on:{"saveWorkspace":_vm.addNewWorkspace}}),_c('Workspaces',{attrs:{"workspaces":_vm.allWorkspaces}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }