<template>
  <div>
    <div class="custom-label" v-if="props.label">{{ props.label }}</div>
    <div>
      <div v-if="props.type === 'password'">
        <v-text-field
          :disabled="disabled"
          dense
          v-model="props.value"
          @input="$emit('input', $event)"
          :rules="props.rules"
          :prepend-inner-icon="props.prependInnerIcon"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :type="showPassword ? 'text' : 'password'"
          :outlined="outlined"
          :class="props.customClass"
          :style="customStyle"
          @keyup.enter="enter"
        ></v-text-field>
      </div>
      <div v-else-if="props.type === 'select'">
        <v-select
          dense
          :disabled="disabled"
          v-model="props.value"
          @input="$emit('input', $event)"
          :items="props.items"
          :rules="props.rules"
          :prepend-inner-icon="props.prependInnerIcon"
          :type="props.type"
          :outlined="outlined"
          :class="props.customClass"
          :style="customStyle"
          @keyup.enter="enter"
        ></v-select>
      </div>
      <div v-else>
        <v-text-field
          dense
          :disabled="disabled"
          v-model="props.value"
          @input="$emit('input', $event)"
          :rules="props.rules"
          :prepend-inner-icon="props.prependInnerIcon"
          :append-icon="props.appendIcon"
          :placeholder="props.placeholder"
          :type="props.type"
          :outlined="outlined"
          :class="props.customClass"
          :style="customStyle"
          @@keyup.enter="enter"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    props: {
      type: Object,
      default: {},
    },
    disabled: { type: Boolean, default: false },
    customStyle: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    enter(event) {
      event.preventDefault();
      this.$emit('enter');
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-text-field .v-input__slot) {
  border-radius: 8px;
  font-size: 14px;
}
:deep(.v-icon.v-icon) {
  font-size: 18px;
}

.custom-label {
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 500;
}

:deep(.v-messages) {
  font-size: 10px;
}
:deep(.v-text-field.v-text-field--enclosed .v-text-field__details) {
  padding: 0 0 0 2px;
  margin-bottom: 1px;
}
</style>
