var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"480px","persistent":""},model:{value:(_vm.dialog.value),callback:function ($$v) {_vm.$set(_vm.dialog, "value", $$v)},expression:"dialog.value"}},[_c('v-card',{staticClass:"pb-2",attrs:{"rounded":"xl"}},[_c('v-card-title',[_c('span',{staticClass:"text-subtitle-2 font-weight-bold text-capitalize"},[_vm._v(" Create New Workspace ")]),_c('v-spacer'),_c('Button',{attrs:{"btn":{
            type: 'button',
            customClass: '',
            block: false,
            icon: 'mdi-close',
          }},on:{"click":function($event){_vm.dialog.value = false}}})],1),_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveWorkspace.apply(null, arguments)}}},[_c('InputField',{attrs:{"disabled":_vm.loading,"props":_vm.inputValues,"outlined":true},on:{"enter":_vm.saveWorkspace}}),_c('v-radio-group',{staticClass:"mt-0",model:{value:(_vm.privacy),callback:function ($$v) {_vm.privacy=$$v},expression:"privacy"}},[_c('v-radio',{staticClass:"mb-2 workspace-modal-radio pa-3 d-flex align-start",attrs:{"value":"open","active-class":"workspace-radio-active"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"color":"black"}},[_c('p',{staticClass:"fs-14 fw-600 mb-0"},[_vm._v("Open")]),_c('p',{staticClass:"caption mb-0"},[_vm._v("Anyone can Join")])])]},proxy:true}])}),_c('v-radio',{staticClass:"mb-2 workspace-modal-radio pa-3 d-flex align-start",attrs:{"value":"closed","active-class":"workspace-radio-active"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"color":"black"}},[_c('p',{staticClass:"fs-14 fw-600 mb-0"},[_vm._v("Closed")]),_c('p',{staticClass:"caption mb-0"},[_vm._v("Only invited people can join")])])]},proxy:true}])})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('Button',{attrs:{"btn":{
            type: 'submit',
            customClass: 'mt-2',
            label: 'Cancel',
            block: false,
          }},on:{"click":_vm.closeModal}}),_c('Button',{attrs:{"loading":_vm.loading,"disabled":!_vm.isFormValid,"btn":{
            type: 'submit',
            color: 'primary',
            customClass: 'mt-2',
            label: 'Create',
            block: false,
          }},on:{"click":_vm.saveWorkspace}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }