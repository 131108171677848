<template>
  <v-btn
    v-if="btn.label"
    :loading="loading"
    :disabled="disabled"
    depressed
    :outlined="btn.outlined"
    :type="btn.type"
    :color="btn.color"
    :class="`${btn.customClass}`"
    :block="btn.block"
    :small="btn.small"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <v-icon size="22" left v-if="btn && btn?.leftIcon">
      {{ btn.leftIcon }}
    </v-icon>
    {{ btn.label }}
    <v-icon right v-if="btn && btn?.rightIcon">
      {{ btn.rightIcon }}
    </v-icon>
  </v-btn>
  <v-btn
    v-else
    :loading="loading"
    :disabled="disabled"
    depressed
    fab
    x-small
    :type="btn.type"
    :color="btn.color"
    :class="`${btn.customClass} rounded-pill`"
    :block="btn.block"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <v-icon>{{ btn.icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    btn: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0;
  border-radius: 12px;
}
</style>
