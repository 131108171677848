export default {
  // ? Authentication & User endpoints
  login: 'api/auth/login',
  logout: 'api/auth/logout',
  signUp: 'api/auth/register',
  forgotPassword: 'api/auth/forgot-password',
  ResetPassword: 'api/auth/reset-password',

  updateProfile: 'api/settings/update-profile',
  changePassword: 'api/settings/change-password',
  deleteAccount: 'api/settings/delete-user',

  // ? Workspace endpoints
  workspaceMembers: 'api/workspace/',
  inviteMembers: 'api/workspace/invite-member',
  createWorkSpace: 'api/workspace/create-workspace',
  getAllWorkSpaces: 'api/workspace/get-workspaces',
  updateWorkSpace: 'api/workspace/update-workspace',
  deleteWorkSpace: 'api/workspace/delete-workspace',

  // add videos in workspace
  addVideo: 'api/workspace/add-file',
  fetchvideos: 'api/recordings/all',
  fetchWorkspaceVideo: 'api/workspace/get-workspace-videos',
  showUserFiles: 'api/workspace/get-user-files',
  getRoles: 'api/roles',
  changePasswordAttribute: 'api/file-password',
  manageEmail: 'api/manage-access',

  leaveWorkSpace: 'api/workspace/leave-workspace',

  savedRecordings: 'api/recordings/all',
  deleteRecordings: 'api/recordings/delete',
  archiveRecordings: 'api/recordings/update/archive',
  renameRecordings: 'api/recordings/update/rename',
  convertToMp3: 'api/change-format',
  changeAccess: 'api/recordings/update/access',
  audioRecording: 'api/recordings/stream-file',
  playAudio: 'api/viewFile',

  //Folders
  getFolderLibrary: 'api/folders/user-folders',
  createFolder: 'api/folders/create',
  renameFolder: 'api/folders/update',
  deleteFolderLibrary: 'api/folders/delete',
  deleteFolderWorkspace: 'api/folders/remove-folder',
  getFolderFiles: 'api/folders/folder-files',
  addVideoToFolder: 'api/folders/add-files',
  addFoldertoWorkspace: 'api/workspace/share-folder',

  //videoPlayer
  TrimControls: 'api/trim-file',
  fetchVideo: 'api/recordings/stream-file/',
  // Use placeholder for file name
  getData: 'api/recordings/get-file/{fileName}', // Use placeholder for file name
  DownloadVideo: 'api/recordings/stream-file/{fileName}/download',
  //password
  checkPassword: 'api/file-password-check',
  getFile: 'api/get-file/{fileName}',
  viewFile: 'api/viewFile',
};
