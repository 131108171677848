import moment from 'moment';

export const formatRecordedDate = (data) => {
  return moment(data).format('MMM DD YYYY, h:mm a');
};

export const debounce = (fn, wait) => {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer); // ? clear any pre-existing timer
    }
    const context = this; // ? get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // ? call the function if time expires
    }, wait);
  };
};

export default {
  install(Vue) {
    // Vue.prototype.formatRecordedDate = function (data) {
    //   return moment(data).format('MMM DD YYYY, h:mm a');
    // };
  },
};
