import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import snackbarPlugin from '@/helpers/snackbar';
import apiRequestPlugin from '@/services/apiRequest';
import InputField from '@/components/common/InputField.vue';
import Button from '@/components/common/CustomButton.vue';
import helpersPlugin from './helpers/helpers';

Vue.config.productionTip = false;
Vue.use(snackbarPlugin);
Vue.use(apiRequestPlugin);
Vue.use(helpersPlugin);
Vue.component('InputField', InputField);
Vue.component('Button', Button);
Vue.component('ListItem', () => import('@/components/common/ListItem.vue'));

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
