<template>
  <v-tooltip content-class="tooltip-button" v-if="isTruncated" top>
    <template v-slot:activator="{ on, attrs }">
      <span class="truncated-text" v-on="on" v-bind="attrs">
        {{ truncatedText }}
      </span>
    </template>
    <span>{{ fullText }}</span>
  </v-tooltip>
  <span v-else>{{ fullText }} </span>
</template>

<script>
export default {
  props: {
    maxLength: {
      type: Number,
      default: 10,
    },
    text: {
      type: String,
      default: 'hello ',
    },
  },
  computed: {
    fullText() {
      return this.text.trim();
    },
    truncatedText() {
      return this.fullText.length > this.maxLength
        ? this.fullText.slice(0, this.maxLength) + '...'
        : this.fullText;
    },
    isTruncated() {
      return this.fullText.length > this.maxLength;
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-navigation-drawer {
  background-color: #f7f7f8;
}
</style>
