/**
 * Generates a lighter HSL color based on a given name.
 *
 * @param {string} name - The name used to compute the avatar color.
 * @returns {string} - The HSL color code for the avatar.
 */
export function getAvatarColor(name) {
  if (!name) {
    return 'hsl(0, 0%, 70%)'; // Default light grey for invalid input
  }

  const hash = getUniqueHash(name);

  // Adjust the range for slightly lighter shades
  const hue = hash % 360;
  const saturation = 65 + (hash % 15); // Range: 65-79% (slightly higher saturation)
  const lightness = 65 + (hash % 10); // Range: 70-79% (slightly higher lightness)

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

/**
 * Generates a unique hash for a given string.
 *
 * @param {string} name - The string to hash.
 * @returns {number} - A positive integer hash value.
 */
function getUniqueHash(name) {
  let hash = 0;

  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  return Math.abs(hash);
}
